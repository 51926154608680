<template>
  <common-dialog
    v-model="visible"
    :hide-on-close="false"
    :title="title"
    activator
    middle
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <template #activator>
      <v-btn color="primary" @click="visible = true">
        {{ title }}
      </v-btn>
    </template>
    <template #default>
      <template v-if="!init">
        <h6 class="text-h6 my-3 text-center">
          Описание
        </h6>
        <p class="text-justify">
          Этот профориентационный тест поможет определить подходящий для учащегося тип будущей профессии.
        </p>
        <h6 class="text-h6 my-3 text-center">
          Инструкция
        </h6>
        <ul class="mb-3">
          <li>Всего {{ steps }} пар деятельности.</li>
          <li>В каждой паре нужно выбрать один подходящий вариант.</li>
        </ul>
        <p class="text-justify">
          Если Вам всё понятно &ndash; можете приступить к тестированию.
        </p>
      </template>
      <template v-else-if="isStepStage || isLastStepStage">
        <h6 class="text-h6 my-3 text-center">
          Что Вам больше нравится?
        </h6>
        <v-list rounded>
          <v-list-item-group
            v-model="currentAnswer"
            color="primary"
            mandatory
          >
            <v-list-item
              v-for="answer in questions[step]"
              :key="answer.text"
            >
              <template #default="{ active }">
                <v-list-item-icon>
                  <v-icon>
                    {{ active ? 'mdi-radiobox-marked' : 'mdi-radiobox-blank' }}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="font-wrap">
                    {{ answer.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </template>
      <template v-else>
        <h6 class="text-h6 my-3 text-center">
          Результат:
        </h6>
        <p><b>Ваш профиль: </b>{{ (result || {}).name }}</p>
        <p>{{ (result || {}).text }}</p>
      </template>
    </template>
    <template #buttons>
      <v-dialog-transition mode="out-in">
        <v-btn
          key="start-btn"
          v-if="!init"
          color="primary"
          @click="init = true"
        >
          Начать{{ $vuetify.breakpoint.xsOnly ? '' : ' тестирование' }}
        </v-btn>
        <v-btn
          key="step-btn"
          v-else-if="isStepStage"
          color="primary"
          @click="saveAnswer"
        >
          {{ $vuetify.breakpoint.xsOnly ? 'Далее' : 'Следующий вопрос' }}
        </v-btn>
        <v-btn
          key="end-btn"
          v-else-if="isLastStepStage"
          color="primary"
          @click="showResults"
        >
          Завершить{{ $vuetify.breakpoint.xsOnly ? '' : ' тест' }}
        </v-btn>
      </v-dialog-transition>
      <v-dialog-transition mode="out-in">
        <v-spacer
          key="counter-spacer"
          v-if="isStepStage || isLastStepStage"
        />
      </v-dialog-transition>
      <v-dialog-transition mode="out-in">
        <v-btn
          key="counter-btn"
          v-if="isStepStage || isLastStepStage"
          text
          color="secondary"
        >
          {{ step + 1 }} / {{ steps }}
        </v-btn>
      </v-dialog-transition>
    </template>
  </common-dialog>
</template>

<script>
import CommonDialog from '@/components/CommonDialog'

export default {
  name: 'TestKlimov',
  components: { CommonDialog },
  data: () => ({
    title: 'Тест по методике Климова',
    visible: false,
    init: false,
    questions: [
      [
        {
          text: 'Ухаживать за животными',
          selected: false
        },
        {
          text: 'Обслуживать машины, приборы (следить, регулировать)',
          selected: false
        }
      ],
      [
        {
          text: 'Помогать больным',
          selected: false
        },
        {
          text: 'Составлять таблицы, схемы, программы для вычислительных машин',
          selected: false
        }
      ],
      [
        {
          text: 'Следить за качеством книжных иллюстраций, плакатов, художественных открыток, грампластинок',
          selected: false
        },
        {
          text: 'Следить за состоянием, развитием растений',
          selected: false
        }
      ],
      [
        {
          text: 'Обрабатывать материалы (дерево, ткань, металл, пластмассу и т.п.)',
          selected: false
        },
        {
          text: 'Доводить товары до потребителя, рекламировать, продавать',
          selected: false
        }
      ],
      [
        {
          text: 'Обсуждать научно-популярные книги, статьи',
          selected: false
        },
        {
          text: 'Обсуждать художественные книги (или пьесы, концерты)',
          selected: false
        }
      ],
      [
        {
          text: 'Выращивать молодняк (животных какой-либо породы)',
          selected: false
        },
        {
          text: 'Тренировать товарищей (или младших) в выполнении каких-либо действий (трудовых, учебных, спортивных)',
          selected: false
        }
      ],
      [
        {
          text: 'Копировать рисунки, изображения (или настраивать музыкальные инструменты)',
          selected: false
        },
        {
          text: 'Управлять каким-либо грузовым (подъемным или транспортным) средством – подъемным краном, трактором, тепловозом и др.',
          selected: false
        }
      ],
      [
        {
          text: 'Сообщать, разъяснять людям нужные им сведения (в справочном бюро, на экскурсии и т.д.)',
          selected: false
        },
        {
          text: 'Оформлять выставки, витрины (или участвовать в подготовке пьес, концертов)',
          selected: false
        }
      ],
      [
        {
          text: 'Ремонтировать вещи, изделия (одежду, технику), жилище',
          selected: false
        },
        {
          text: 'Искать и исправлять ошибки в текстах, таблицах, рисунках',
          selected: false
        }
      ],
      [
        {
          text: 'Лечить животных',
          selected: false
        },
        {
          text: 'Выполнять вычисления, расчеты',
          selected: false
        }
      ],
      [
        {
          text: 'Выводить новые сорта растений',
          selected: false
        },
        {
          text: 'Конструировать, проектировать новые виды промышленных изделий (машины, одежду, дома, продукты питания и т.п.)',
          selected: false
        }
      ],
      [
        {
          text: 'Разбирать споры, ссоры между людьми, убеждать, разъяснять, наказывать, поощрять',
          selected: false
        },
        {
          text: 'Разбираться в чертежах, схемах, таблицах (проверять, уточнять, приводить в порядок)',
          selected: false
        }
      ],
      [
        {
          text: 'Наблюдать, изучать работу кружков художественной самодеятельности',
          selected: false
        },
        {
          text: 'Наблюдать, изучать жизнь микробов',
          selected: false
        }
      ],
      [
        {
          text: 'Обслуживать, налаживать медицинские приборы, аппараты',
          selected: false
        },
        {
          text: 'Оказывать людям медицинскую помощь при ранениях, ушибах, ожогах и т.п.',
          selected: false
        }
      ],
      [
        {
          text: 'Художественно описывать, изображать события (наблюдаемые и представляемые)',
          selected: false
        },
        {
          text: 'Составлять точные описания-отчеты о наблюдаемых явлениях, событиях, измеряемых объектах и др.',
          selected: false
        }
      ],
      [
        {
          text: 'Делать лабораторные анализы в больнице',
          selected: false
        },
        {
          text: 'Принимать, осматривать больных, беседовать с ними, назначать лечение',
          selected: false
        }
      ],
      [
        {
          text: 'Красить или расписывать стены помещений, поверхность изделий',
          selected: false
        },
        {
          text: 'Осуществлять монтаж или сборку машин, приборов',
          selected: false
        }
      ],
      [
        {
          text: 'Организовать культпоходы сверстников или младших в театры, музеи, экскурсии, туристические походы и т.п.',
          selected: false
        },
        {
          text: 'Играть на сцене, принимать участие в концертах',
          selected: false
        }
      ],
      [
        {
          text: 'Изготовлять по чертежам детали, изделия (машины, одежду), строить здания',
          selected: false
        },
        {
          text: 'Заниматься черчением, копировать чертежи, карты',
          selected: false
        }
      ],
      [
        {
          text: 'Вести борьбу с болезнями растений, с вредителями леса, сада',
          selected: false
        },
        {
          text: 'Работать на клавишных машинах (пишущей машинке, телетайпе, наборной машине и др.)',
          selected: false
        }
      ]
    ],
    step: 0,
    currentAnswer: 0,
    result: null
  }),
  watch: {
    visible (val) {
      if (!val) {
        this.init = false
        this.step = 0
        this.resetAnswer()
        this.result = null
        this.questions = this.questions.map(i => i.map(a => ({
          ...a,
          selected: false
        })))
      }
    }
  },
  computed: {
    steps () {
      return this.questions.length
    },
    lastStep () {
      return this.steps - 1
    },
    isStepStage () {
      return this.init && this.step < this.lastStep
    },
    isLastStepStage () {
      return this.init && this.step === this.lastStep
    },
    currentQuestion () {
      return this.questions[this.step] || {}
    }
  },
  methods: {
    resetAnswer () {
      this.currentAnswer = 0
    },
    saveAnswer () {
      this.questions[this.step][this.currentAnswer].selected = true
      this.step++
      this.resetAnswer()
    },
    showResults () {
      this.saveAnswer()
      const questions = this.questions
      const types = [
        {
          name: 'Человек – Природа',
          text: 'Сюда входят профессии, в которых человек имеет дело с различными явлениями неживой и живой ' +
            'природы, например, биолог, географ, геолог, математик, физик, химик и другие профессии, ' +
            'относящиеся к разряду естественных наук.',
          score:
            questions[0][0].selected +
            questions[2][1].selected +
            questions[5][0].selected +
            questions[9][0].selected +
            questions[10][0].selected +
            questions[12][1].selected +
            questions[15][0].selected +
            questions[19][0].selected
        },
        {
          name: 'Человек – Техника',
          text: 'В эту группу профессий включены различные виды трудовой деятельности, в которых человек имеет ' +
            'дело с техникой, её использованием или конструированием, например, профессия инженера, ' +
            'оператора, машиниста, механизатора, сварщика и т.п.',
          score:
            questions[0][1].selected +
            questions[3][0].selected +
            questions[6][1].selected +
            questions[8][0].selected +
            questions[10][1].selected +
            questions[13][0].selected +
            questions[16][1].selected +
            questions[18][0].selected
        },
        {
          name: 'Человек – Человек',
          text: 'Сюда включены все виды профессий, предполагающих взаимодействие людей, например, политика, ' +
            'религия, педагогика, психология, медицина, торговля, право.',
          score:
            questions[1][0].selected +
            questions[3][1].selected +
            questions[5][1].selected +
            questions[7][0].selected +
            questions[11][0].selected +
            questions[13][1].selected +
            questions[15][1].selected +
            questions[17][0].selected
        },
        {
          name: 'Человек – Знак',
          text: 'В эту группу включены профессии, касающиеся создания, изучения и использования различных ' +
            'знаковых систем, например, лингвистика, языки математического программирования, способы ' +
            'графического представления результатов наблюдений и т.п.',
          score:
            questions[1][1].selected +
            questions[4][0].selected +
            questions[8][1].selected +
            questions[9][1].selected +
            questions[11][1].selected +
            questions[14][0].selected +
            questions[18][1].selected +
            questions[19][1].selected
        },
        {
          name: 'Человек – Художественный образ',
          text: 'Эта группа профессий представляет собой различные виды художественно-творческого труда, ' +
            'например, литература, музыка, театр, изобразительное искусство.',
          score:
            questions[2][0].selected +
            questions[4][1].selected +
            questions[6][0].selected +
            questions[7][1].selected +
            questions[12][0].selected +
            questions[14][1].selected +
            questions[16][0].selected +
            questions[17][1].selected
        }
      ]
      types.sort((a, b) => {
        if (a.score === b.score) return 0
        return a.score > b.score ? -1 : 1
      })
      this.result = types[0]
    }
  }
}
</script>

<style scoped>

</style>
