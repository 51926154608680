<template>
  <common-dialog
    v-model="visible"
    :hide-on-close="false"
    :title="title"
    activator
    middle
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <template #activator>
      <v-btn color="primary" @click="visible = true">
        {{ title }}
      </v-btn>
    </template>
    <template #default>
      <template v-if="!init">
        <h6 class="text-h6 my-3 text-center">
          Описание
        </h6>
        <p class="text-justify">
          В результате Вы узнаете, к какому типу профессий у вас есть наклонности, а какие профессии вам не очень
          подходят.
        </p>
        <p class="text-justify">
          Этот тест &ndash; только приблизительная оценка Ваших профессиональных способностей.
        </p>

        <h6 class="text-h6 my-3 text-center">
          Инструкция
        </h6>
        <ul class="mb-3">
          <li>Вам предстоит ответить на {{ steps }} вопросов.</li>
          <li>В каждом вопросе можно выбрать только один ответ.</li>
          <li>Пропускать вопросы нельзя.</li>
        </ul>
        <p class="text-justify">
          Если Вам всё понятно &ndash; можете приступить к тестированию.
        </p>
      </template>
      <template v-else-if="isStepStage || isLastStepStage">
        <template v-for="(question, index) in questions">
          <template v-if="index === step">
            <h6
              class="text-h6 my-3 text-center"
              :key="question.text + '_header'"
            >
              {{ question.text }}
            </h6>
            <v-list
              :key="question.text"
              rounded
            >
              <v-list-item-group
                v-model="question.selected"
                color="primary"
                mandatory
              >
                <v-list-item
                  v-for="answer in question.answers"
                  :value="answer.value"
                  :key="answer.text"
                >
                  <template #default="{ active }">
                    <v-list-item-icon>
                      <v-icon>
                        {{ active ? 'mdi-radiobox-marked' : 'mdi-radiobox-blank' }}
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="font-wrap">
                        {{ answer.text }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </template>
        </template>
      </template>
      <template v-else>
        <h6 class="text-h6 my-3 text-center">
          Результат:
        </h6>
        <p><b>Ваша группа: </b>{{ resultIndex + 1 }}</p>
        <p>{{ results[resultIndex].text }}</p>
      </template>
    </template>
    <template #buttons>
      <v-dialog-transition mode="out-in">
        <v-btn
          key="start-btn"
          v-if="!init"
          color="primary"
          @click="init = true"
        >
          Начать{{ $vuetify.breakpoint.xsOnly ? '' : ' тестирование' }}
        </v-btn>
        <v-btn
          key="step-btn"
          v-else-if="isStepStage"
          color="primary"
          :disabled="!currentQuestion.selected"
          @click="step++"
        >
          {{ $vuetify.breakpoint.xsOnly ? 'Далее' : 'Следующий вопрос' }}
        </v-btn>
        <v-btn
          key="end-btn"
          v-else-if="isLastStepStage"
          color="primary"
          @click="showResults"
        >
          Завершить{{ $vuetify.breakpoint.xsOnly ? '' : ' тест' }}
        </v-btn>
      </v-dialog-transition>
      <v-dialog-transition mode="out-in">
        <v-spacer
          key="counter-spacer"
          v-if="isStepStage || isLastStepStage"
        />
      </v-dialog-transition>
      <v-dialog-transition mode="out-in">
        <v-btn
          key="counter-btn"
          v-if="isStepStage || isLastStepStage"
          text
          color="secondary"
        >
          {{ step + 1 }} / {{ steps }}
        </v-btn>
      </v-dialog-transition>
    </template>
  </common-dialog>
</template>

<script>
import CommonDialog from '@/components/CommonDialog'

export default {
  name: 'TestYourProfession',
  components: { CommonDialog },
  data: () => ({
    title: 'Тест «Ты и Твоя профессия»',
    visible: false,
    init: false,
    questions: [
      {
        text: 'Новогодняя ночь для вас – лучшее время, чтобы:',
        selected: 1,
        answers: [
          {
            text: 'Выспаться',
            value: 1
          },
          {
            text: 'Посмотреть телевизор вместе с семьей',
            value: 2
          },
          {
            text: 'Оказаться в кругу друзей',
            value: 3
          }
        ]
      },
      {
        text: 'Из трех подарков вы предпочли бы:',
        selected: 1,
        answers: [
          {
            text: 'Удочку, набор для вышивания',
            value: 1
          },
          {
            text: 'Коньки или лыжи',
            value: 2
          },
          {
            text: 'Турпутевку или билет на интересное представление',
            value: 3
          }
        ]
      },
      {
        text: 'Отправляться в путешествие лучше всего:',
        selected: 1,
        answers: [
          {
            text: 'В одиночку',
            value: 1
          },
          {
            text: 'С семьей или друзьями',
            value: 2
          },
          {
            text: 'С незнакомой группой, чтобы была возможность обрести новых друзей',
            value: 3
          }
        ]
      },
      {
        text: 'Если бы вы оказались в одиночестве на острове или в лесу, то:',
        selected: 1,
        answers: [
          {
            text: 'Почувствовали бы полную свободу',
            value: 1
          },
          {
            text: 'Занялись бы поиском выхода или каким-нибудь делом',
            value: 2
          },
          {
            text: 'Ощутили бы тоску, неприкаянность, страх',
            value: 3
          }
        ]
      },
      {
        text: 'В свое свободное время вы любите:',
        selected: 1,
        answers: [
          {
            text: 'Читать, посещать библиотеку, шахматную секцию, зоопарк, лес, ловить рыбу, мечтать',
            value: 1
          },
          {
            text: 'Рисовать, читать, заниматься спортом, музыкой, шитьем или вязанием, ' +
              'ходить в походы, разговаривать по телефону, смотреть телевизор',
            value: 2
          },
          {
            text: 'Заниматься спортом, танцами, играть в ансамбле, петь в хоре, ' +
              'участвовать в спектаклях и концертах, путешествовать с друзьями, ходить с компанией в кино',
            value: 3
          }
        ]
      }
    ],
    results: [
      {
        min: 5,
        max: 7,
        text: 'Эти люди, как правило, малообщительные, стеснительные, замкнутые. ' +
          'Они не любят шумных, незнакомых компаний, ' +
          'встречи с незнакомыми людьми им доставляют беспокойство. ' +
          'Это значит, что им не очень подходят профессии, которые требуют активного общения ' +
          '(продавец, учитель, журналист и психолог). ' +
          'Зато они будут хорошо себя чувствовать в научной лаборатории или на собственной ферме, ' +
          'в питомнике, лесничестве, охотохозяйстве. ' +
          'Таким людям подойдет работа ' +
          'программиста, слесаря или токаря, профессия бухгалтера, оператора ЭВМ.'
      },
      {
        min: 8,
        max: 11,
        text: 'Эти люди очень общительны и чувствуют себя прекрасно в любой компании. ' +
          'Они не боятся новых знакомств, легко сходятся с людьми. ' +
          'Но они могут обходиться длительное время и без общения. ' +
          'Таким подойдут любые профессии.'
      },
      {
        min: 12,
        max: 15,
        text: 'Эти люди общительные, но они не могут долго оставаться в одиночестве - ' +
          'в них слишком много энергии и силы. ' +
          'Таким людям лучше выбирать профессию, которая будет связана с большими коллективами людей, ' +
          'с активным общением: агента по рекламе, коммерческого директора, продавца, дилера, учителя, ' +
          'брокера или тренера.'
      }
    ],
    step: 0,
    resultIndex: null
  }),
  watch: {
    visible (val) {
      if (!val) {
        this.init = false
        this.step = 0
        this.resultIndex = null
        this.questions = this.questions.map(i => ({
          ...i,
          selected: 1
        }))
      }
    }
  },
  computed: {
    steps () {
      return this.questions.length
    },
    lastStep () {
      return this.steps - 1
    },
    isStepStage () {
      return this.init && this.step < this.lastStep
    },
    isLastStepStage () {
      return this.init && this.step === this.lastStep
    },
    currentQuestion () {
      return this.questions[this.step] || {}
    }
  },
  methods: {
    showResults () {
      this.step++
      const score = this.questions.reduce((sum, i) => sum + i.selected, 0)
      this.resultIndex = this.results.findIndex(i => i.min <= score && i.max >= score)
    }
  }
}
</script>

<style scoped>

</style>
