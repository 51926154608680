<template>
  <common-dialog
    v-model="visible"
    :hide-on-close="false"
    :title="title"
    activator
    large
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <template #activator>
      <v-btn color="primary" @click="visible = true">
        {{ title }}
      </v-btn>
    </template>
    <template #default>
      <template v-if="!init">
        <h6 class="text-h6 my-3 text-center">
          Описание
        </h6>
        <p class="text-justify">
          Тест «Одно из двух» основан на методике Дж. Холланда в модификации Г.В. Резапкиной.
        </p>
        <p class="text-justify">
          В данной методике объединены две самые известные и теоретически обоснованные типологии,
          которые дополняют друг друга.
          По Е.А. Климову, каждый человек согласно своим личностным качествам выбирает определенный тип профессии.
          В зависимости от предмета труда выделяют следующие группы:
          «человек – человек»,
          «человек – техника»,
          «человек – природа»,
          «человек – знаковая система»,
          «человек – художественный образ».
          По Дж. Холланду, профессиональный успех,
          удовлетворенность своим трудом зависят от профессионального типа личности
          (реалистический, интеллектуальный, социальный, офисный, предпринимательский, артистический).
        </p>
        <h6 class="text-h6 my-3 text-center">
          Инструкция
        </h6>
        <ul class="mb-3">
          <li>Всего {{ steps }} пар профессий.</li>
          <li>В каждой паре нужно выбрать один подходящий вариант.</li>
        </ul>
        <p class="text-justify">
          Если Вам всё понятно &ndash; можете приступить к тестированию.
        </p>
      </template>
      <template v-else-if="isStepStage || isLastStepStage">
        <h6 class="text-h6 my-3 text-center">
          Какая из двух профессий Вам больше по душе?
        </h6>
        <v-list rounded>
          <v-list-item-group
            v-model="currentAnswer"
            color="primary"
            mandatory
          >
            <v-list-item
              v-for="answer in questions[step]"
              :key="answer.text"
            >
              <template #default="{ active }">
                <v-list-item-icon>
                  <v-icon>
                    {{ active ? 'mdi-radiobox-marked' : 'mdi-radiobox-blank' }}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="font-wrap">
                    {{ answer.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </template>
      <template v-else>
        <h6 class="text-h6 my-3 text-center">
          Результат:
        </h6>
        <p>
          <b>Ваше отношение к профессиональным типам</b> личности показано в первой таблице ниже,
          а во второй таблице показано описание каждого типа и связи между ними.
        </p>
        <v-simple-table v-if="results.length" dense>
          <thead>
            <tr>
              <th>Буква</th>
              <th>Название</th>
              <th>Выраженность</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="type in results"
              :key="type.name"
            >
              <td><b>{{ type.letter }}</b></td>
              <td>{{ type.name }}</td>
              <td :class="type.css">
                {{ type.degree }}
              </td>
            </tr>
          </tbody>
        </v-simple-table>

        <h6 class="text-h6 my-3 text-center">
          Описание типов
        </h6>
        <v-simple-table v-if="results.length" dense>
          <thead>
            <tr>
              <th>Буква</th>
              <th>Название</th>
              <th>Описание</th>
              <th>Противоположные</th>
              <th>Близкие</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="type in results"
              :key="type.name"
            >
              <td><b>{{ type.letter }}</b></td>
              <td>{{ type.name }}</td>
              <td>{{ type.text }}</td>
              <td class="error--text">{{ type.opposite }}</td>
              <td class="success--text">{{ type.near }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </template>
    </template>
    <template #buttons>
      <v-dialog-transition mode="out-in">
        <v-btn
          key="start-btn"
          v-if="!init"
          color="primary"
          @click="init = true"
        >
          Начать{{ $vuetify.breakpoint.xsOnly ? '' : ' тестирование' }}
        </v-btn>
        <v-btn
          key="step-btn"
          v-else-if="isStepStage"
          color="primary"
          @click="saveAnswer"
        >
          {{ $vuetify.breakpoint.xsOnly ? 'Далее' : 'Следующий вопрос' }}
        </v-btn>
        <v-btn
          key="end-btn"
          v-else-if="isLastStepStage"
          color="primary"
          @click="showResults"
        >
          Завершить{{ $vuetify.breakpoint.xsOnly ? '' : ' тест' }}
        </v-btn>
      </v-dialog-transition>
      <v-dialog-transition mode="out-in">
        <v-spacer
          key="counter-spacer"
          v-if="isStepStage || isLastStepStage"
        />
      </v-dialog-transition>
      <v-dialog-transition mode="out-in">
        <v-btn
          key="counter-btn"
          v-if="isStepStage || isLastStepStage"
          text
          color="secondary"
        >
          {{ step + 1 }} / {{ steps }}
        </v-btn>
      </v-dialog-transition>
    </template>
  </common-dialog>
</template>

<script>
import CommonDialog from '@/components/CommonDialog'

export default {
  name: 'TestHolland',
  components: { CommonDialog },
  data: () => ({
    title: 'Тест «Одно из двух»',
    visible: false,
    init: false,
    questions: [
      [
        // Р, С
        {
          text: 'Автомеханик',
          type: 0,
          klimov: 0,
          selected: false
        },
        {
          text: 'Физиотерапевт',
          type: 2,
          klimov: 0,
          selected: false
        }
      ],
      [
        // И, П
        {
          text: 'Специалист по защите информации',
          type: 1,
          klimov: 0,
          selected: false
        },
        {
          text: 'Логистик ',
          type: 4,
          klimov: 0,
          selected: false
        }
      ],
      [
        // О, А
        {
          text: 'Оператор связи',
          type: 3,
          klimov: 0,
          selected: false
        },
        {
          text: 'Кинооператор',
          type: 5,
          klimov: 0,
          selected: false
        }
      ],
      [
        // Р, С
        {
          text: 'Водитель',
          type: 0,
          klimov: 0,
          selected: false
        },
        {
          text: 'Кассир',
          type: 2,
          klimov: 0,
          selected: false
        }
      ],
      [
        // И, П
        {
          text: 'Инженер-конструктор',
          type: 1,
          klimov: 0,
          selected: false
        },
        {
          text: 'Менеджер по автопродажам',
          type: 4,
          klimov: 0,
          selected: false
        }
      ],
      [
        // О, А
        {
          text: 'Авиадиспетчер',
          type: 3,
          klimov: 0,
          selected: false
        },
        {
          text: 'Дизайнер сайтов и компьютерных программ',
          type: 5,
          klimov: 0,
          selected: false
        }
      ],
      [
        // Р, С
        {
          text: 'Ветеринар',
          type: 0,
          klimov: 1,
          selected: false
        },
        {
          text: 'Эколог',
          type: 2,
          klimov: 1,
          selected: false
        }
      ],
      [
        // И, П
        {
          text: 'Биолог-исследователь',
          type: 1,
          klimov: 1,
          selected: false
        },
        {
          text: 'Фермер',
          type: 4,
          klimov: 1,
          selected: false
        }
      ],
      [
        // О, А
        {
          text: 'Лаборант',
          type: 3,
          klimov: 1,
          selected: false
        },
        {
          text: 'Дрессировщик',
          type: 5,
          klimov: 1,
          selected: false
        }
      ],
      [
        // Р, С
        {
          text: 'Агроном',
          type: 0,
          klimov: 1,
          selected: false
        },
        {
          text: 'Санитарный врач',
          type: 2,
          klimov: 1,
          selected: false
        }
      ],
      [
        // И, П
        {
          text: 'Селекционер',
          type: 1,
          klimov: 1,
          selected: false
        },
        {
          text: 'Заготовитель сельхозпродуктов',
          type: 4,
          klimov: 1,
          selected: false
        }
      ],
      [
        // О, А
        {
          text: 'Санитарный инспектор',
          type: 3,
          klimov: 1,
          selected: false
        },
        {
          text: 'Ландшафтный дизайнер',
          type: 5,
          klimov: 1,
          selected: false
        }
      ],
      [
        // Р, С
        {
          text: 'Массажист',
          type: 0,
          klimov: 2,
          selected: false
        },
        {
          text: 'Воспитатель',
          type: 2,
          klimov: 2,
          selected: false
        }
      ],
      [
        // И, П
        {
          text: 'Преподаватель',
          type: 1,
          klimov: 2,
          selected: false
        },
        {
          text: 'Предприниматель',
          type: 4,
          klimov: 2,
          selected: false
        }
      ],
      [
        // О, А
        {
          text: 'Администратор',
          type: 3,
          klimov: 2,
          selected: false
        },
        {
          text: 'Режиссёр театра и кино',
          type: 5,
          klimov: 2,
          selected: false
        }
      ],
      [
        // Р, С
        {
          text: 'Официант',
          type: 0,
          klimov: 2,
          selected: false
        },
        {
          text: 'Врач',
          type: 2,
          klimov: 2,
          selected: false
        }
      ],
      [
        // И, П
        {
          text: 'Психолог',
          type: 1,
          klimov: 2,
          selected: false
        },
        {
          text: 'Торговый агент',
          type: 4,
          klimov: 2,
          selected: false
        }
      ],
      [
        // О, А
        {
          text: 'Страховой агент',
          type: 3,
          klimov: 2,
          selected: false
        },
        {
          text: 'Хореограф',
          type: 5,
          klimov: 2,
          selected: false
        }
      ],
      [
        // Р, С
        {
          text: 'Ювелир-гравёр',
          type: 0,
          klimov: 3,
          selected: false
        },
        {
          text: 'Журналист',
          type: 2,
          klimov: 3,
          selected: false
        }
      ],
      [
        // И, П
        {
          text: 'Искусствовед',
          type: 1,
          klimov: 3,
          selected: false
        },
        {
          text: 'Продюсер',
          type: 4,
          klimov: 3,
          selected: false
        }
      ],
      [
        // О, А
        {
          text: 'Редактор',
          type: 3,
          klimov: 3,
          selected: false
        },
        {
          text: 'Музыкант',
          type: 5,
          klimov: 3,
          selected: false
        }
      ],
      [
        // Р, С
        {
          text: 'Дизайнер интерьера',
          type: 0,
          klimov: 3,
          selected: false
        },
        {
          text: 'Экскурсовод',
          type: 2,
          klimov: 3,
          selected: false
        }
      ],
      [
        // И, П
        {
          text: 'Композитор',
          type: 1,
          klimov: 3,
          selected: false
        },
        {
          text: 'Арт-директор',
          type: 4,
          klimov: 3,
          selected: false
        }
      ],
      [
        // О, А
        {
          text: 'Музейный работник',
          type: 3,
          klimov: 3,
          selected: false
        },
        {
          text: 'Актёр театра и кино',
          type: 5,
          klimov: 3,
          selected: false
        }
      ],
      [
        // Р, С
        {
          text: 'Верстальщик',
          type: 0,
          klimov: 4,
          selected: false
        },
        {
          text: 'Гид-переводчик',
          type: 2,
          klimov: 4,
          selected: false
        }
      ],
      [
        // И, П
        {
          text: 'Лингвист',
          type: 1,
          klimov: 4,
          selected: false
        },
        {
          text: 'Антикризисный управляющий',
          type: 4,
          klimov: 4,
          selected: false
        }
      ],
      [
        // О, А
        {
          text: 'Корректор',
          type: 3,
          klimov: 4,
          selected: false
        },
        {
          text: 'Художественный редактор',
          type: 5,
          klimov: 4,
          selected: false
        }
      ],
      [
        // Р, С
        {
          text: 'Наборщик текстов',
          type: 0,
          klimov: 4,
          selected: false
        },
        {
          text: 'Юристконсульт',
          type: 2,
          klimov: 4,
          selected: false
        }
      ],
      [
        // И, П
        {
          text: 'Программист',
          type: 1,
          klimov: 4,
          selected: false
        },
        {
          text: 'Брокер',
          type: 4,
          klimov: 4,
          selected: false
        }
      ],
      [
        // О, А
        {
          text: 'Бухгалтер',
          type: 3,
          klimov: 4,
          selected: false
        },
        {
          text: 'Литературный переводчик',
          type: 5,
          klimov: 4,
          selected: false
        }
      ]
    ],
    typesKlimov: [
      'Человек – Техника',
      'Человек – Природа',
      'Человек – Человек',
      'Человек – Художественный образ',
      'Человек – Знак'
    ],
    step: 0,
    currentAnswer: 0,
    results: []
  }),
  watch: {
    visible (val) {
      if (!val) {
        this.init = false
        this.step = 0
        this.resetAnswer()
        this.results = []
        this.questions = this.questions.map(i => i.map(a => ({
          ...a,
          selected: false
        })))
      }
    }
  },
  computed: {
    steps () {
      return this.questions.length
    },
    lastStep () {
      return this.steps - 1
    },
    isStepStage () {
      return this.init && this.step < this.lastStep
    },
    isLastStepStage () {
      return this.init && this.step === this.lastStep
    },
    currentQuestion () {
      return this.questions[this.step] || {}
    }
  },
  methods: {
    resetAnswer () {
      this.currentAnswer = 0
    },
    saveAnswer () {
      this.questions[this.step][this.currentAnswer].selected = true
      this.step++
      this.resetAnswer()
    },
    getTypeItemsScore (items, typeId) {
      return items.reduce((sum, i) => sum + +(i.type === typeId && i.selected), 0)
    },
    getTypeScoreCssClasses (score) {
      if (score < 2) {
        return {
          degree: 'Тип не выражен',
          css: ''
        }
      }
      if (score >= 2 && score <= 4) {
        return {
          degree: 'Слабо выражен',
          css: 'error--text font-weight-bold'
        }
      }
      if (score >= 5 && score <= 7) {
        return {
          degree: 'Средне выражен',
          css: 'warning--text'
        }
      }
      return {
        degree: 'Ярко выражен',
        css: 'success--text font-weight-bold'
      }
    },
    showResults () {
      this.saveAnswer()
      const professions = []
      this.questions.forEach((answer) => {
        professions.push(...answer)
      })
      let types = [
        {
          name: 'Реалистический',
          letter: 'Р',
          text: 'Люди, относящиеся к этому типу, предпочитают выполнять работу, требующую силы, ловкости, ' +
            'подвижности, хорошей координации движений, навыков практической работы. Результаты труда ' +
            'профессионалов этого типа ощутимы и реальны — их руками создан весь окружающий нас ' +
            'предметный мир. Люди реалистического типа охотнее делают, чем говорят, они настойчивы и ' +
            'уверены в себе, в работе предпочитают четкие и конкретные указания. Придерживаются ' +
            'традиционных ценностей, поэтому критически относятся к новым идеям.',
          opposite: 2,
          near: [1, 3],
          score: 0,
          css: ''
        },
        {
          name: 'Интеллектуальный',
          letter: 'И',
          text: 'Людей этого типа отличают аналитические способности, рационализм, независимость и ' +
            'оригинальность мышления, умение точно формулировать и излагать свои мысли, решать ' +
            'логические задачи, генерировать новые идеи. Они часто выбирают научную и исследовательскую ' +
            'работу и условия, дающие свободу для творчества. Работа способна увлечь их настолько, ' +
            'что стирается грань между работой и досугом. Мир идей для них может быть важнее, чем ' +
            'общение с людьми. Материальное благополучие для них обычно не главное.',
          opposite: 4,
          near: [1, 5],
          score: 0,
          css: ''
        },
        {
          name: 'Социальный',
          letter: 'С',
          text: 'Люди этого типа предпочитают работу, связанную с обучением, воспитанием, лечением, ' +
            'консультированием, обслуживанием. Люди социального типа гуманны, чувствительны, активны, ' +
            'ориентированы на социальные нормы, способны понять эмоциональное состояние другого ' +
            'человека. Для них характерно хорошее речевое развитие, живая мимика, интерес к людям, ' +
            'готовность прийти на помощь. Материальное благополучие для них обычно не на первом месте.',
          opposite: 0,
          near: [4, 5],
          score: 0,
          css: ''
        },
        {
          name: 'Офисный',
          letter: 'О',
          text: 'Люди этого типа склонны к работе, связанной с обработкой и систематизацией информации, ' +
            'предоставленной в виде условных знаков, цифр, формул, текстов (ведение документации, ' +
            'установление количественных соотношений между числами и условными знаками), отличаются ' +
            'аккуратностью, пунктуальностью, практичностью, ориентированы на социальные нормы, ' +
            'предпочитают четко определенную деятельность. Материальное благополучие для них более ' +
            'значимо, чем для других типов. Склонны к работе, не связанной с широкими контактами и ' +
            'принятием ответственных решений.',
          opposite: 5,
          near: [0, 4],
          score: 0,
          css: ''
        },
        {
          name: 'Предпринимательский',
          letter: 'П',
          text: 'Люди этого типа находчивы, практичны, быстро ориентируются в сложной обстановке, ' +
            'самостоятельно принимают решения, социально активны, готовы рисковать, ищут острые ' +
            'ощущения. Любят и умеют общаться. Имеют высокий уровень притязаний. Избегают занятий, ' +
            'требующих усидчивости, большой и длительной концентрации внимания. Для них значимо ' +
            'материальное благополучие. Предпочитают деятельность, требующую энергии, организаторских ' +
            'способностей, связанную с руководством, управлением и влиянием на людей.',
          opposite: 1,
          near: [2, 3],
          score: 0,
          css: ''
        },
        {
          name: 'Артистический',
          letter: 'А',
          text: 'Люди этого типа оригинальны, независимы в принятии решений, редко ориентируются на ' +
            'социальные нормы и одобрение, обладают необычным взглядом на жизнь, гибкостью и скоростью ' +
            'мышления, эмоциональной чувствительностью. Отношения с людьми строят, опираясь на свои ' +
            'ощущения, эмоции, воображение, интуицию. Они обладают обостренным восприятием и талантом ' +
            'общения, избегают жесткой регламентации, предпочитая свободный график работы. Часто ' +
            'выбирают профессии, связанные с литературой, театром, кино, музыкой, изобразительным ' +
            'искусством.',
          opposite: 3,
          near: [1, 2],
          score: 0,
          css: ''
        }
      ]
      types = types.map((type, index) => {
        const score = this.getTypeItemsScore(professions, index)
        const { degree, css } = this.getTypeScoreCssClasses(score)
        return Object.freeze({
          ...type,
          index,
          opposite: types[type.opposite].letter,
          near: type.near.map(i => types[i].letter).join(', '),
          score,
          degree,
          css
        })
      })
      types.sort((a, b) => {
        if (a.score === b.score) return 0
        return a.score > b.score ? -1 : 1
      })
      this.results = types
    }
  }
}
</script>

<style scoped>

</style>
