<template>
  <common-dialog
    v-model="visible"
    :hide-on-close="false"
    :title="title"
    activator
    middle
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <template #activator>
      <v-btn color="primary" @click="visible = true">
        {{ title }}
      </v-btn>
    </template>
    <template #default>
      <template v-if="!init">
        <h6 class="text-h6 my-3 text-center">
          Описание
        </h6>
        <p class="text-justify">
          Опросник разработан А.Е. Голомштоком. Предназначен для изучения интересов и склонностей школьников старших
          классов в различных сферах деятельности. Голомшток выделяет 23 таких сферы: физика, математика, химия,
          астрономия, биология, медицина, сельское хозяйство, филология, журналистика, история, искусство, геология,
          география, общественная деятельность, право, транспорт, педагогика, рабочие специальности, сфера
          обслуживания, строительство, легкая промышленность, техника, электротехника.
        </p>
        <p class="text-justify">
          Этот тест &ndash; только приблизительная оценка Ваших профессиональных способностей.
        </p>

        <h6 class="text-h6 my-3 text-center">
          Инструкция
        </h6>
        <p class="text-justify">
          Для определения ведущих интересов Вам предлагается перечень из {{ steps }} вопросов.
          Каждый вопрос можно оценить одним из 5 вариантов:
        </p>
        <ul class="mb-3">
          <li>очень нравится,</li>
          <li>просто нравится,</li>
          <li>не знаю или сомневаюсь,</li>
          <li>не нравится,</li>
          <li>совсем не нравится.</li>
        </ul>
        <p class="text-justify">
          Если Вам всё понятно &ndash; можете приступить к тестированию.
        </p>
      </template>
      <template v-else-if="isStepStage || isLastStepStage">
        <h6 class="subtitle-2 my-3 text-center">
          Любите ли Вы? Нравится ли Вам? Хотели бы Вы?
        </h6>
        <h6 class="text-h6 my-3 text-center">
          {{ currentQuestion }}
        </h6>
        <v-list rounded>
          <v-list-item-group
            v-model="currentAnswer"
            color="primary"
            mandatory
          >
            <v-list-item
              v-for="answer in answers"
              :value="answer.value"
              :key="answer.text"
            >
              <template #default="{ active }">
                <v-list-item-icon>
                  <v-icon>
                    {{ active ? 'mdi-radiobox-marked' : 'mdi-radiobox-blank' }}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="font-wrap">
                    {{ answer.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </template>
      <template v-else>
        <h6 class="text-h6 my-3 text-center">
          Результат:
        </h6>
        <v-simple-table dense>
          <thead>
            <tr>
              <th>Сфера</th>
              <th>Степень интереса</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="result in resultsInterest"
              :key="result.text"
            >
              <td><b>{{ result.text }}</b></td>
              <td :class="result.type.css">{{ result.type.text }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </template>
    </template>
    <template #buttons>
      <v-dialog-transition mode="out-in">
        <v-btn
          key="start-btn"
          v-if="!init"
          color="primary"
          @click="init = true"
        >
          Начать{{ $vuetify.breakpoint.xsOnly ? '' : ' тестирование' }}
        </v-btn>
        <v-btn
          key="step-btn"
          v-else-if="isStepStage"
          color="primary"
          @click="saveAnswer"
        >
          {{ $vuetify.breakpoint.xsOnly ? 'Далее' : 'Следующий вопрос' }}
        </v-btn>
        <v-btn
          key="end-btn"
          v-else-if="isLastStepStage"
          color="primary"
          @click="showResults"
        >
          Завершить{{ $vuetify.breakpoint.xsOnly ? '' : ' тест' }}
        </v-btn>
      </v-dialog-transition>
      <v-dialog-transition mode="out-in">
        <v-spacer
          key="counter-spacer"
          v-if="isStepStage || isLastStepStage"
        />
      </v-dialog-transition>
      <v-dialog-transition mode="out-in">
        <v-btn
          key="counter-btn"
          v-if="isStepStage || isLastStepStage"
          text
          color="secondary"
        >
          {{ step + 1 }} / {{ steps }}
        </v-btn>
      </v-dialog-transition>
    </template>
  </common-dialog>
</template>

<script>
import CommonDialog from '@/components/CommonDialog'

export default {
  name: 'TestInterestsMap',
  components: { CommonDialog },
  data: () => ({
    title: 'Составление карты интересов',
    visible: false,
    init: false,
    questions: Object.freeze([
      'Знакомиться с жизнью растений и животных',
      'Занятия и чтение книг по географии',
      'Читать художественную или научно-популярную литературу о геологических экспедициях',
      'Уроки и книги по анатомии и физиологии человека',
      'Выполнять ежедневную домашнюю работу',
      'Читать научно-популярную литературу о физических открытиях, жизни и деятельности выдающихся физиков',
      'Читать об открытиях в химии или о жизни и деятельности выдающихся химиков',
      'Читать технические журналы',
      'Читать статьи в научно-популярных журналах о достижениях в области электроники и радиотехники',
      'Знакомиться с разными металлами и их свойствами',
      'Узнавать о разных породах древесины и об их практическом применении',
      'Узнавать о достижениях в области строительства',
      'Читать книги, смотреть фильмы о водителях различных видов транспорта (автомобильного, железнодорожного и т.д.)',
      'Читать книги, смотреть фильмы о летчиках',
      'Знакомиться с военной техникой',
      'Читать книги об исторических событиях',
      'Читать произведения классиков русской и зарубежной литературы',
      'Читать и обсуждать публицистические статьи и очерки',
      'Обсуждать текущие дела и события в учебном заведении, городе, стране',
      'Интересоваться педагогической работой',
      'Читать книги, смотреть фильмы о работе милиции',
      'Заботиться о порядке в собственных вещах, красивом виде помещения',
      'Читать книги из серии "Занимательная математика"',
      'Изучать экономическую географию',
      'Заниматься иностранным языком',
      'Знакомиться с жизнью выдающихся художников, историей искусства',
      'Знакомиться с жизнью выдающихся артистов, встречаться с ними, коллекционировать их фотографии',
      'Знакомиться с жизнью и творчеством выдающихся музыкантов, с вопросами теории музыки',
      'Читать спортивные газеты, журналы, литературу о спорте',
      'Изучать биологию, ботанику, зоологию',
      'Знакомиться с различными странами по книгам и телепередачам',
      'Читать о жизни и деятельности знаменитых геологов',
      'Интересоваться достижениями медицины',
      'Посещать с экскурсиями предприятия легкой промышленности',
      'Читать книги из серии "Занимательная физика"',
      'Изучать химические явления в природе',
      'Знакомиться с новейшими достижениями техники',
      'Интересоваться работой радиотехника, электрика',
      'Знакомиться с различными измерительными инструментами, используемыми в процессе металлообработки',
      'Наблюдать за изготовлением изделий из дерева (например, мебели)',
      'Наблюдать за работой строителей',
      'Читать литературу о средствах передвижения',
      'Читать книги, смотреть фильмы и телепередачи о моряках',
      'Читать книги, смотреть фильмы и телепередачи на военные темы, знакомиться с историей крупных сражений',
      'Обсуждать текущие политические события в стране и за рубежом',
      'Читать литературно-критические статьи',
      'Слушать радио, смотреть теленовости и тематические телепередачи',
      'Узнавать о событиях, происходящих в городе, стране',
      'Объяснять учащимся трудные для понимания вопросы',
      'Справедливо рассудить поступок знакомого или литературного героя',
      'Обеспечивать семью продуктами, организовывать питание членов семьи',
      'Читать научно-популярную литературу об открытиях в математике, о жизни и деятельности выдающихся математиков',
      'Интересоваться экономическими передачами по телевидению',
      'Читать художественную литературу на иностранном языке',
      'Заниматься художественным оформлением праздников',
      'Посещать театр',
      'Слушать оперную или симфоническую музыку',
      'Посещать спортивные соревнования, смотреть спортивные передачи',
      'Посещать дополнительные занятия по биологии',
      'Посещать дополнительные занятия по географии',
      'Коллекционировать минералы',
      'Изучать функции организма человека, причины возникновения и способы лечения болезней человека',
      'Готовить обед дома',
      'Осуществлять демонстрацию физических опытов',
      'Участвовать в организации опытов с химическими веществами',
      'Ремонтировать различные механизмы',
      'Производить замеры в электросетях с помощью приборов (вольтметра, амперметра)',
      'Конструировать различные предметы и детали из металла',
      'Художественно обрабатывать дерево (вырезать, выпиливать)',
      'Набрасывать эскизы или выполнять чертежи строительных объектов',
      'Проявлять интерес к автомобильному транспорту',
      'Увлекаться парашютным спортом, авиамоделизмом',
      'Заниматься спортивной стрельбой',
      'Изучать историю возникновения различных народов и государств',
      'Писать стихи',
      'Наблюдать за проведением и жизнью других людей',
      'Выполнять организационную общественную работу',
      'Проводить время с маленькими детьми, заниматься и играть с ними',
      'Устанавливать дисциплину среди сверстников',
      'Наблюдать за работой работников сферы обслуживания (повар, официант, продавец)',
      'Участвовать в математических олимпиадах',
      'Просматривать экономические обзоры в газетах и журналах',
      'Слушать радиопередачи на иностранном языке',
      'Посещать музеи, художественные выставки',
      'Публично выступать',
      'Играть на музыкальном инструменте',
      'Участвовать в спортивных соревнованиях',
      'Наблюдать за ростом и развитием животных, растений',
      'Собирать книги по географии',
      'Посещать краеведческий музей',
      'Вникать в работу врача, фармацевта',
      'Шить для себя',
      'Интересоваться физикой',
      'Интересоваться химией',
      'Работать с техникой',
      'Знакомиться с устройством электроаппаратуры',
      'Заниматься слесарным делом',
      'Выполнять столярные работы',
      'Принимать участие в отделочных ремонтных работах',
      'Помогать ГИБДД в работе с пешеходами по правилам уличного движения',
      'Быть членом гребных и яхт-клубов',
      'Участвовать в военизированных играх',
      'Знакомиться с историческими местами',
      'Быть членом литературного клуба',
      'Наблюдать за ведением журналистами телепередач',
      'Публично выступать с сообщениями и докладами',
      'Организовывать работу детей',
      'Устанавливать скрытые причины поступков и поведения людей',
      'Оказывать различные услуги другим людям',
      'Решать сложные математические задачи',
      'Точно вести расчет своих денежных средств',
      'Посещать курсы по изучению иностранного языка',
      'Заниматься в художественной секции',
      'Участвовать в художественной самодеятельности',
      'Посещать музыкальную школу',
      'Заниматься в спортивной секции',
      'Участвовать в биологических олимпиадах и конкурсах',
      'Путешествовать',
      'Принимать участие в геологической экспедиции',
      'Ухаживать за больными',
      'Посещать кулинарные конкурсы и выставки',
      'Участвовать в конкурсных мероприятиях по физике',
      'Решать сложные задачи по химии',
      'Разбираться в технических схемах и чертежах',
      'Разбираться в сложных радиосхемах',
      'Знакомиться с новой техникой, наблюдать за работой промышленного оборудования',
      'Мастерить что-нибудь из дерева своими руками',
      'Помогать в строительных работах',
      'Принимать участие в обслуживании и ремонте автомобиля',
      'Мечтать о самостоятельных полетах на самолете',
      'Строго выполнять распорядок дня',
      'Публично выступать с докладами на исторические темы',
      'Работать с литературными источниками',
      'Принимать участие в дискуссиях, конференциях',
      'Организовывать и проводить коллективные мероприятия',
      'Обсуждать вопросы воспитания детей и подростков',
      'Помогать работникам милиции',
      'Постоянно общаться с различными людьми',
      'Принимать участие в математических олимпиадах, конференциях',
      'Интересоваться вопросами ценообразования, начисления заработной платы',
      'Общаться на иностранном языке',
      'Участвовать в художественных выставках',
      'Принимать участие в театральных постановках',
      'Участвовать в музыкальных конкурсах',
      'Регулярно тренироваться и участвовать в спортивных соревнованиях',
      'Выращивать растения, ухаживать за домашними животными',
      'Проводить топографическую съемку местности',
      'Совершать длительные, трудные походы',
      'Интересоваться деятельностью медицинских работников',
      'Шить, кроить, создавать модели одежды',
      'Решать сложные задачи по физике',
      'Посещать с экскурсиями химические предприятия',
      'Участвовать в выставках технического творчества',
      'Ремонтировать аудиотехнику',
      'Работать на станке',
      'Выполнять по чертежам столярные работы',
      'Принимать сезонное участие в строительстве',
      'Изучать правила дорожного движения для водителей',
      'Изучать морское дело',
      'Читать воспоминания главнокомандующих и полководцев',
      'Знакомиться с историческими местами родного края',
      'Писать рассказы',
      'Писать заметки или очерки в газету',
      'Выполнять общественные поручения',
      'Организовывать детские игровые и праздничные мероприятия',
      'Изучать законодательные документы, гражданское право',
      'Удовлетворять потребности людей в тех или иных услугах',
      'Выполнять математические расчеты по формулам',
      'Интересоваться проблемами региональной экономики',
      'Участвовать в олимпиадах по иностранному языку',
      'Посещать художественные музеи',
      'Играть на сцене в спектаклях',
      'Слушать классическую музыку',
      'Помогать преподавателю физкультуры в проведении занятия'
    ]),
    answers: [
      {
        text: 'Очень нравится',
        value: 2
      },
      {
        text: 'Просто нравится',
        value: 1
      },
      {
        text: 'Не знаю или сомневаюсь',
        value: 0
      },
      {
        text: 'Не нравится',
        value: -1
      },
      {
        text: 'Совсем не нравится',
        value: -2
      }
    ],
    currentAnswer: 0,
    results: [
      {
        text: 'Биология',
        score: 0,
        questions: [1, 30, 59, 88, 117, 146]
      },
      {
        text: 'География',
        score: 0,
        questions: [2, 31, 60, 89, 118, 147]
      },
      {
        text: 'Геология',
        score: 0,
        questions: [3, 32, 61, 90, 119, 148]
      },
      {
        text: 'Медицина',
        score: 0,
        questions: [4, 33, 62, 91, 120, 149]
      },
      {
        text: 'Легкая и пищевая промышленность',
        score: 0,
        questions: [5, 34, 63, 92, 121, 150]
      },
      {
        text: 'Физика',
        score: 0,
        questions: [6, 35, 64, 93, 122, 151]
      },
      {
        text: 'Химия',
        score: 0,
        questions: [7, 36, 65, 94, 123, 152]
      },
      {
        text: 'Техника',
        score: 0,
        questions: [8, 37, 66, 95, 124, 153]
      },
      {
        text: 'Электро- и радиотехника',
        score: 0,
        questions: [9, 38, 67, 96, 125, 154]
      },
      {
        text: 'Металлообработка',
        score: 0,
        questions: [10, 39, 68, 97, 126, 155]
      },
      {
        text: 'Деревообработка',
        score: 0,
        questions: [11, 40, 69, 98, 127, 156]
      },
      {
        text: 'Строительство',
        score: 0,
        questions: [12, 41, 70, 99, 128, 157]
      },
      {
        text: 'Транспорт',
        score: 0,
        questions: [13, 42, 71, 100, 129, 158]
      },
      {
        text: 'Авиация, морское дело',
        score: 0,
        questions: [14, 43, 72, 101, 130, 159]
      },
      {
        text: 'Военные специальности',
        score: 0,
        questions: [15, 44, 73, 102, 131, 160]
      },
      {
        text: 'История',
        score: 0,
        questions: [16, 45, 74, 103, 132, 161]
      },
      {
        text: 'Литература',
        score: 0,
        questions: [17, 46, 75, 104, 133, 162]
      },
      {
        text: 'Журналистика',
        score: 0,
        questions: [18, 47, 76, 105, 134, 163]
      },
      {
        text: 'Общественная деятельность',
        score: 0,
        questions: [19, 48, 77, 106, 135, 164]
      },
      {
        text: 'Педагогика',
        score: 0,
        questions: [20, 49, 78, 107, 136, 165]
      },
      {
        text: 'Юриспруденция',
        score: 0,
        questions: [21, 50, 79, 108, 137, 166]
      },
      {
        text: 'Сфера обслуживания',
        score: 0,
        questions: [22, 51, 80, 109, 138, 167]
      },
      {
        text: 'Математика',
        score: 0,
        questions: [23, 52, 81, 110, 139, 168]
      },
      {
        text: 'Экономика',
        score: 0,
        questions: [24, 53, 82, 111, 140, 169]
      },
      {
        text: 'Иностранные языки',
        score: 0,
        questions: [25, 54, 83, 112, 141, 170]
      },
      {
        text: 'Изобразительное искусство',
        score: 0,
        questions: [26, 55, 84, 113, 142, 171]
      },
      {
        text: 'Сценическое искусство',
        score: 0,
        questions: [27, 56, 85, 114, 143, 172]
      },
      {
        text: 'Музыка',
        score: 0,
        questions: [28, 57, 86, 115, 144, 173]
      },
      {
        text: 'Физкультура и спорт',
        score: 0,
        questions: [29, 58, 87, 116, 145, 174]
      }
    ],
    interestRanges: Object.freeze([
      Object.freeze({
        min: -12,
        max: -6,
        text: 'Абсолютно не интересно',
        css: 'error--text font-weight-bold'
      }),
      Object.freeze({
        min: -5,
        max: -1,
        text: 'Неинтересно',
        css: 'error--text'
      }),
      Object.freeze({
        min: 0,
        max: 4,
        text: 'Слабый интерес',
        css: 'primary--text'
      }),
      Object.freeze({
        min: 5,
        max: 7,
        text: 'Интересно',
        css: 'success--text'
      }),
      Object.freeze({
        min: 8,
        max: 12,
        text: 'Очень интересно',
        css: 'success--text font-weight-bold'
      })
    ]),
    step: 0,
    resultsInterest: []
  }),
  watch: {
    visible (val) {
      if (!val) {
        this.init = false
        this.step = 0
        this.resetAnswer()
        this.resultsInterest = []
        this.results = this.results.map(i => ({
          ...i,
          score: 0
        }))
      }
    }
  },
  computed: {
    steps () {
      return this.questions.length
    },
    lastStep () {
      return this.steps - 1
    },
    isStepStage () {
      return this.init && this.step < this.lastStep
    },
    isLastStepStage () {
      return this.init && this.step === this.lastStep
    },
    currentQuestion () {
      return this.questions[this.step] || ''
    }
  },
  methods: {
    saveAnswer () {
      const result = this.results.find(i => i.questions.includes(this.step + 1)) || {}
      result.score += this.currentAnswer
      this.step++
      this.resetAnswer()
    },
    resetAnswer () {
      this.currentAnswer = 0
    },
    showResults () {
      this.saveAnswer()
      const resultsRanges = []
      this.results.forEach((result) => {
        const type = this.interestRanges.find(i => i.min <= result.score && i.max >= result.score) || {}
        resultsRanges.push(Object.freeze({
          text: result.text,
          score: result.score,
          type
        }))
      })
      resultsRanges.sort((a, b) => {
        if (a.score === b.score) return 0
        return a.score > b.score ? -1 : 1
      })
      this.resultsInterest = resultsRanges
    }
  }
}
</script>

<style scoped>

</style>
