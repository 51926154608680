<template>
  <v-row>
    <v-col cols="12">
      <p>
        Ниже Вы найдёте несколько профориентационных тестов,
        которые помогут определить Вашу будущую профессию,
        профессиональный кластер и
        профессиональную направленность.
      </p>
    </v-col>
    <v-col
      v-for="test in tests"
      :key="test"
      cols="12"
      class="text-center"
    >
      <component
        :is="test"
        activator
      />
    </v-col>
    <v-col cols="12">
      <b>Как работать с тестами:</b>
      <ul>
        <li>выберите тест и нажмите его кнопку;</li>
        <li>откроется окно тестирования;</li>
        <li>сразу после открытия Вы увидите описание теста, инструкцию и кнопку начала тестирования;</li>
        <li>после изучения информации нажмите «Начать тестирование»;</li>
        <li>далее будут следовать вопросы по одному;</li>
        <li>
          вверху будет сам вопрос,
          ниже – список вариантов ответа,
          под ним – кнопка перехода на следующий вопрос,
          а под ней – счётчик вопросов в виде «текущий / всего»;
        </li>
        <li>вариантов ответа может быть несколько в некоторых тестах, но в основном &ndash; только один;</li>
        <li>когда будет получен ответ на последний вопрос, появится информация о результатах;</li>
        <li>такой информацией могут быть таблицы, описания и т.д.;</li>
        <li>чтобы закрыть окно тестирования – нажмите кнопку "Закрыть" справа вверху окна тестирования;</li>
        <li>чтобы начать тест с начала – закройте и снова откройте тест.</li>
      </ul>
    </v-col>
  </v-row>
</template>

<script>
import TestYourProfession from '@/components/Tests/TestYourProfession'
import TestInterestsMap from '@/components/Tests/TestInterestsMap'
import TestKlimov from '@/components/Tests/TestKlimov'
import TestHolland from '@/components/Tests/TestHolland'

export default {
  name: 'Tests',
  components: {
    TestHolland,
    TestKlimov,
    TestInterestsMap,
    TestYourProfession
  },
  data: () => ({
    tests: [
      'test-your-profession',
      'test-interests-map',
      'test-klimov',
      'test-holland'
    ]
  })
}
</script>
